// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-contentful-article-title-js": () => import("./../../../src/pages/articles/{ContentfulArticle.title}.js" /* webpackChunkName: "component---src-pages-articles-contentful-article-title-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contentful-service-name-js": () => import("./../../../src/pages/{ContentfulService.name}.js" /* webpackChunkName: "component---src-pages-contentful-service-name-js" */),
  "component---src-pages-corporate-social-responsibility-js": () => import("./../../../src/pages/corporate-social-responsibility.js" /* webpackChunkName: "component---src-pages-corporate-social-responsibility-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

